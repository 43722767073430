div.ball {
  background-color: transparent;
  width: 37rem;
  height: 37rem;
  position: absolute;
  z-index: 10;
  pointer-events: none;
  mix-blend-mode: difference;
  left: -50%;
  top: -50%;
}
div.ball svg {
  animation: rotate 15s infinite linear;
  width: 100%;
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: 10;
}
@media (max-width: 700px) {
  div.ball {
      display: none;
  }
}

@keyframes heartbeat {
  0% {
    transform: translate(-50%, -50%) scale(1) rotate(90deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2) rotate(180deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
  }
}

@keyframes morph {
  0% {
    border-radius: 50% 50% 40% 60% / 50% 60% 40% 50%;
  }
  33% {
    border-radius: 40% 60% 50% 50% / 50% 40% 60% 50%;
  }
  66% {
    border-radius: 50% 50% 60% 40% / 60% 50% 50% 40%;
  }
  100% {
    border-radius: 50% 50% 40% 60% / 50% 60% 40% 50%;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}