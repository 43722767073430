body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html,body,
div,span,
applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,
pre,a,abbr,acronym,
address,big,cite,code,
del,dfn,em,img,
ins,kbd,q,s,samp,small,
strike,strong,sub,sup,tt,var,
b,u,i,center,
dl,dt,dd,ol,ul,li,
fieldset,form,label,legend,
table,caption,tbody,tfoot,
thead,tr,th,td,article,aside,
canvas,details,embed,figure,
figcaption,footer,header,hgroup,
menu,nav,output,ruby,section,summary,time,mark,
audio,video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Inter", sans-serif;
  vertical-align: baseline;
}
article,aside,
details,figcaption,
figure,footer,
header,hgroup,
menu,nav,section {
  display: block;
}
body {
  line-height: 1;
}
body * {
  box-sizing: border-box;
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #fff;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  font-size: 11.2px;
}
@media (min-width: 3000px) {
  html {
      font-size: 15.2px;
  }
}
@media (max-width: 900px) {
  html {
      font-size: 11px;
  }
}
@media (max-width: 500px) {
  html {
      font-size: 7px;
  }
}
body {
  background: linear-gradient(90deg, #101010 0%, #101010 21.27%, #0f0f0f 100%);
}

/* Global */

/* slick */
.slick-slider {
  width: 100%;
}
.slick-track {
  display: flex !important;
  gap: 1.5rem;
}
.slick-prev:before,
.slick-next:before {
  font-size: 4rem;
}
.artists .slick-slide {
  width: 100% !important;
}

.slick-slide {
	transform: scale(0.8);
	transition: all 0.4s ease-in-out;
	/* padding: 40px 0; */
}


.slick-slide img {
	max-width: 100%;
	transition: all 0.4s ease-in-out;
}

.slick-center {
	transform: scale(1.1);
}
@media (max-width: 500px) {
  .artists .slick-slider .slick-slide {
    padding: 0 5rem;
  }
  .slick-prev {
    left: 0 !important;
    z-index: 10000;
  }
  .slick-next {
    right: -4px !important;
    z-index: 10000;
  }
}


/* end slick */

.space-nowrap {
  white-space: nowrap;
}
.container {
  width: 95%;
  margin: 0 auto;
}
.w-50 {
  width: 50%;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.w-100 {
  width: 100%;
}

/* flex */
.flex {
  display: flex;
}
.flex--align-center {
  align-items: center ;
}
.flex--justify-center {
  justify-content: center ;
}
.flex--space-between {
  justify-content: space-between ;
}
.flex--align-baseline {
  align-items: baseline ;
}
.flex--start {
  align-items: flex-start ;
}
.flex--end {
  align-items: flex-end ;
}
.flex--align-stretch {
  align-items: stretch ;
}
.flex--justify-end {
  justify-content: flex-end;
}
.flex--justify-start {
  justify-content: flex-start ;
}
.flex--grow {
  flex-grow: 1;
}
.flex--wrap {
  flex-wrap: wrap;
}
.flex--direction__row {
  flex-direction: row;
}
.flex--direction__column {
  flex-direction: column;
}
.align-self--start {
  align-self: flex-start;
}
.align-self--end {
  align-self: flex-end ;
}
.align-self--center {
  align-self: center;
}
.flex-1 {
  flex: 1;
}
.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-3 {
  gap: 3rem;
}
.gap-5 {
  gap: 5rem;
}
.gap-6 {
  gap: 6rem;
}
.gap-10 {
  gap: 10rem;
}
.gap-20 {
  gap: 20rem;
}
.release-banner,
.catalog-hero,
.artist-banner,
.about-hero {
  margin-top: 13rem !important;
}
@media (max-width: 500px) {
  .release-banner,
  .catalog-hero,
  .artist-banner,
  .about-hero {
  margin-top: 24.25rem !important;
}
}

/* end global */

/* header */
.header {
  padding: 2.625rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
  background: linear-gradient(0deg, rgb(0 0 0 / 0%) 41%, rgb(16 16 16) 100%);
}
.header.is-open {
  background: none;
}
@media (max-width: 500px) {
  .header {
   padding: 5.625rem 3rem; 
  }
}
.header__logo {
  cursor: pointer;
  margin-left: 1rem;
}
@media (max-width: 500px) {
  .header__logo img {
      width: 9rem;
  }
}
.header-menu {
    border-radius: 2.625rem;
    border: 1px solid #343434;
    width: 7rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 400ms;
}
.header-menu.is-open {
    border: 1px solid transparent;
}
.header-menu.is-open span:nth-child(1) {
    border-radius: 38% 62% 0% 100% / 30% 100% 0% 70% 
}
.header-menu.is-open span:nth-child(2) {
    border-radius: 61% 39% 70% 30% / 100% 30% 70% 0%;
}
.header-menu.is-open span:nth-child(3) {
    border-radius: 100% 0% 52% 48% / 69% 36% 64% 31% 
}
.header-menu.is-open span:nth-child(4) {
    border-radius: 21% 79% 38% 62% / 0% 59% 41% 100% 
}
.header-menu-button {
  width: 1rem;
  height: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 0.188rem;
}
.header-menu-button span {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background-color: #fff;
    transition: all 400ms;
}
.hero-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15rem;
}
@media (max-width: 500px) {
  .hero-text {
      margin-top: 0rem;
  }
}
.hero-text h1 {
  color: #fff;
  text-align: center;
  font-size: 2.375rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 500px) {
  .hero-text h1 {
    line-height: 25px;
  }
}
.menu {
  background: linear-gradient(90deg, #101010 0%, #101010 21.27%, #0f0f0f 100%);
  width: 100%;
  height: 100dvh;
  margin: 0 auto;
  position: fixed;
  top: 0rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  border: 1px solid #323232;
  border-radius: 0.5rem;
  padding: 1rem;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

}
.menu.is-open {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    -webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.menu-content  {
    border: 1px solid #343434;
    border-radius: 1.5rem;
    padding: 10rem;
    height: 100dvh;
}
@media (max-width: 500px) {
  .menu-content {
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1rem;
  }
}
.menu-content a {
  font-size: 12rem;
  color: #323232;
  font-weight: 600;
  margin-top: 2rem;
  position: relative;
  cursor: pointer;
}
@media (max-width: 500px) {
  .menu-content a {
    font-size: 10rem;
    color: #fff;
  }
  .menu-content a .label {
    font-size: 3rem;
    position: absolute;
    right: -3rem;
  }
}
.menu-content a .label {
  font-size: 7rem;
  color: #fff;
  margin-top: 2rem;
  position: absolute;
  top: 0;
  font-style: normal;
  font-weight: 300;
}
.menu-content a .label.left-aligned {
  left: -350px;
}
@media (max-width: 500px) {
  .menu-content a .label {
    font-size: 3rem;
  }
}
.menu-content a:hover {
  color: #fff;
}
.menu-content a:nth-child(1) {
  align-self: flex-end;
}
.menu-content a:nth-child(2) {
  align-self: flex-start;
}
.menu-content a:nth-child(3) {
  align-self: flex-end;
}
/* end header */

/* hero */
.hero {
  margin-top: 18rem;
}
.hero-logo {
  position: relative;
  width: 111.3rem;
  height: 25rem;
}
@media (min-width: 3000px) {
  .hero-logo {
      width: 82.3rem;
  }
}
@media (max-width: 1099px) {
  .hero-logo {
      width: 48rem;
  }
  .hero-logo img:first-child {
    width: 100%;
  }
  .hero-logo img:last-child {
    width: 100%;
  }
}
.hero-logo img:first-child {
  position: absolute;
  left: 0;
  mix-blend-mode: color-dodge;
}
.hero-logo img:last-child {
  position: absolute;
  top: 0.813rem;
  right: 1.25rem;
}
.hero-icon {
  margin-top: 10rem;
}
@media (max-width: 900px) {
  .hero-icon svg {
    width: 4rem;
  }
 }
/* end hero */

/* home page */
.content {
  margin-top: 13rem;
}
.content p {
  /* color: #202020; */
  color: rgba(256, 256, 256, 0.4);
  font-size: 5.625rem;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
}
.content p span {
  /* color: #202020; */
  color: rgba(256, 256, 256, 0.4);
  font-weight: 600;
  padding-left: 14.3rem;
}
.content p small {
  color: rgba(256, 256, 256, 0.4);
  padding-left: 12.3rem;
}
@media (max-width: 900px) {
  .content p,
  .content p small,
  .content p span {
    font-size: 4rem;
    color: #fff;
  }  
}
.asymmetric-links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 6rem auto;
  max-width: 93rem;
}
.asymmetric-links a {
  color: #fff;
  font-size: 3.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.asymmetric-links span {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 4rem;
}
.asymmetric-links a:nth-child(1) {
  padding-left: 0;
  text-align: center;
}
.asymmetric-links a:nth-child(2) {
  padding-left: 84%;
}
.asymmetric-links a:nth-child(3) {
  padding-left: 33%;
}
@media (max-width: 600px) {
  .asymmetric-links a:nth-child(3) {
      padding-left: 19%;
  }
}
#artists {
  margin-bottom: 15rem;
}
.artists {
  max-width: 135rem;
  margin-top: 8.75rem;
}
.artists-item {
  position: relative;
  cursor: pointer;
  border-radius: 0; 
  

}
.artists-item:hover .top-action,
.artists-item:hover .bottom-action {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}
.artists-item .artist-img img {
  border-radius: 0rem;
  transition: border-radius 0.5s ease-in-out;
}
.artists-item:hover .artist-img img {
  border-radius: 1rem;
  transition: border-radius 0.5s ease-in-out;
}
.top-action {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 93%;
  top: 1rem;
  gap: 7rem;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}
@media (max-width: 1099px) {
  .top-action,
  .bottom-action {
    opacity: 1 !important;
    visibility: visible !important;
    width: 84% !important;
    border-radius: 5rem !important;
  }
}
@media (max-width: 700px) {
  .top-action {
    left: 2rem;
    top: 4rem;
  }
}

.top-label,
.top-media {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.top-label span,
.top-media a {
  border-radius: 2rem;
  background: rgba(226, 226, 226, 0.1);
  backdrop-filter: blur(10px);
  padding: 1rem 1.5rem;
}
@media (max-width: 500px) {
  .top-label span,
  .top-media a {
    padding: 1rem 1.5rem !important;
    border-radius: 5rem !important;
  }
  .top-media a img {
    width: 2rem;
  }
}
.top-label span {
  color: #fff;
}
@media (max-width: 500px) {
  .top-label span {
    font-size: 17px;
    font-weight: 700;
  }
}
.top-media span {
  width: 3.5rem;
  height: 3.1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-action {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  border-radius: 2rem;
  background: rgba(226, 226, 226, 0.1);
  backdrop-filter: blur(10px);
  width: 93%;
  left: 50%;
  transform: translate(-50%, 0px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 700px) {
  .bottom-action {
    bottom: 5rem;
    width: 95% !important;
    left: 50%;
  }
}
.bottom-action span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem;
}
@media (max-width: 700px) {
  .bottom-action span {
    padding: 1rem 1rem;
  }
}
.bottom-action p {
  color: #fff;
  font-size: 3.5rem;
  font-weight: 500;
}
@media (max-width: 700px) {
  .bottom-action p {
    font-size: 2rem;
  }
}
.bottom-action button {
  border: 2px solid #fff;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: transparent;
  cursor: pointer;
}
@media (max-width: 700px) {
  .bottom-action button {
    width: 3rem;
    height: 3rem;
  }
}
/* end home page */

/* footer */

.footer {
  /* background-image: url(../public/images/global/hero-shape-1.svg); */
  mix-blend-mode: color-dodge;
  height: 70dvh;
  position: absolute;
  z-index: 0;  
  background-repeat: no-repeat;
  background-position: 46% 76%;
  background-size: 460rem;
  gap: 24rem;
  width: 96%;
  border: 1px solid #a1a1a1;  
}
@media (max-width: 500px) {
  .footer {
    height: 80%;
  }
}
.footer::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgb(32 32 32 / 61%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.footer-container {
  max-width: 100rem;
}
@media (max-width: 500px) {
  .footer-container {
    gap: 10rem;
    padding: 5rem;
  }
}
.footer-left {
  justify-content: flex-start !important;
  align-items: center;
  margin-left: 4rem;
}
.footer-left,
.footer-right {
  display: flex;
  justify-content: center;
  /* height: 20rem; */
  flex-direction: column;
  text-wrap: balance;
}
.footer-left .logo img {
  width: 27rem;
}
.footer-right p {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 30px;
}
@media (max-width: 500px) {
  .footer-right p {
    font-size: 16px;
    line-height: normal;
  }
}
.footer-right p a {
  font-weight: 700;
  line-height: normal;
}
.footer-links {
  margin-top: 6rem;
  justify-content: flex-start;
}
@media (max-width: 500px) {
  .footer-links {
    margin-top: 12rem;
  }
}
.footer-links a {
  color: #fff;
  font-size: 1.75rem;
}
.footer-links svg {
  height: 100%;
}
@media (max-width: 500px) {
  .footer-links a {
    font-size: 16px;
  }
}
.footer-links .links {
  width: 41rem;
  gap: 4rem;
}
.footer-links .name {
  color: #fff;
  font-size: 2rem;
}
@media (max-width: 500px) {
  .footer-links .name {
    display: none;
  }
}
/* end footer */

/* single artist  */
#artist-banner {
  width: 100%;
  display: flex;
  justify-content: center;
}
.artist-banner {
  height: 100%;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  max-width: 68rem;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
}
.artist-banner .overlay {
  background-image: url(../public/images/global/hero-shape-1.svg);
  position: absolute;
  top: 50%;
  width: 80rem;
  height: 43rem;
  background-repeat: no-repeat;
  mix-blend-mode: color-dodge;
  z-index: -1;
  background-size: 315%;
  background-position: 44% 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(162 162 162 / 72%);
  border-radius: 0.5rem;
}
@media (max-width: 500px) {
  .artist-banner .overlay {
    width: 48rem;
    border: none;
  }
}
.artist-banner .overlay::before {
  content: '';
  background-color: rgb(15 15 15 / 62%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.artist-banner .artist-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media (max-width: 500px) {
 .artist-img img {
    width: 100%;
  }
}
.artist-label {
  display: flex;
  gap: 1rem;
  align-self: flex-start;
}
.artist-banner .artist-label p {
  border-radius: 5rem;
  background: rgba(226, 226, 226, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 2rem 3.25rem;
  color: #fff;
  font-size: 2.438rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.artist-banner .artist-links {
  align-items: center;
  align-self: end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  padding-right: 5rem;
}
.artist-banner .artist-links a {
  background: rgba(226, 226, 226, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 5rem;
  width: 6.5rem;
  height: 5.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 500px) {
  .artist-banner .artist-links a svg {
     width: 4rem;
   }
 }
.artist-banner .artist-links p svg {
}
.artist-banner .artist-links a:nth-child(3) {
  margin-left: 2rem;
}
.artist-banner .artist-links a:nth-child(4) {
  margin-left: 1rem;
}
.artist-banner .artist-name {
  font-size: 15.625rem;
  color: #fff;
  align-self: flex-start;
  padding-right: 33rem;
}
.artist-banner .artist-name.artist-name--small {
  font-size: 7.625rem;
  padding-right: 8rem;
}
@media (max-width: 500px) {
  .artist-banner .artist-name {
    font-size: 12rem;
    padding-right: 0rem;
    padding-left: 3rem;
  }
  .artist-name p {
    width: 51rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
 }
.about-artist {
  padding: 0rem 4.3rem;
  margin-top: 15rem;
}
@media (max-width: 500px) {
  .about-artist {
    flex-direction: column;
  }
  .about-artist>div {
    flex-direction: column;
    gap: 1rem;
  }
 }
.about-artist p {
  white-space: nowrap;
  width: 10rem;
}
.about-artist span,
.about-artist p,
.video-text p,
.video-text span {
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media (max-width: 500px) {
  .about-artist span,
  .about-artist p,
  .video-text p,
  .video-text span {
    font-size: 16px;
  }
 }
.media-container {
  width: 96%;
  margin: 5rem auto;
}
.soundcloud-list,
.video-list {
  border-radius: 1.5rem;
  background: rgba(226, 226, 226, 0.1);
  padding: 3rem 5rem;
  border: 1px solid transparent;
}
.video-list {
  width: 34.5rem;
}
.video-text {
  margin: 5rem 0rem;
}
.soundcloud-list:hover,
.video-list:hover {
  border: 1px solid #7f7f7f;
}
/* end single artist */


/* About page */
.about-hero {
  background-image: url(../public/images/global/hero-shape-1.svg);
  width: 70rem;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 50% -16rem;
  background-size: 203rem;
  mix-blend-mode: color-dodge;
  position: relative;
  height: 56rem;
}
@media (max-width: 500px) {
  .about-hero {
    width: 55rem;
    background-position: 50% -18rem;
    background-size: 173rem;
  }
 }
.about-hero-overlay {
  background: rgb(0 0 0 / 66%) -1344.728px -991.492px / 324.13% 315.968% no-repeat;
  width: 80rem;
  height: 100%;
  position: absolute;
  z-index: -1;
}
@media (max-width: 500px) {
  .about-hero-overlay {
    width: 55rem;
  }
 }
.about-hero-logo  {
  border: 1px solid #828282;
  padding: 11rem;
  border-radius: 2rem;
}
@media (max-width: 500px) {
  .about-hero-logo {
    padding: 6rem;
  } 
 }
.about-hero-logo img {
  width: 43rem;
}
.about-hero-top-text span:first-child {
  margin-left: 12rem;
}
.about-hero-bottom-text span:last-child {
  margin-right: 12rem;
}
@media (max-width: 500px) {
  .about-hero-top-text span:first-child {
    margin-left: 0;
  } 
  .about-hero-bottom-text span:last-child {
    margin-right: 0;
  } 
 }
.about-hero-top-text  {
  position: absolute;
  top: -3rem;
  left: -8rem;
}
@media (max-width: 500px) {
  .about-hero-top-text,
  .about-hero-bottom-text {
    top: -3rem;
    left: 0;
    flex-direction: row;
    justify-content: flex-end;
  } 
 }
.about-hero-bottom-text  {
  position: absolute;
  bottom: 16rem;
  right: -9rem;
}
@media (max-width: 500px) {
  .about-hero-bottom-text {
    right: 0;
    bottom: 28rem;
  } 
 }
.about-hero-top-text span,
.about-hero-bottom-text span {
  background: rgba(131, 128, 128, 0.40);
  border-radius: 3rem;
  padding: 1.8rem 2.5rem;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #fff;
  font-weight: 600;
  width: max-content;
  text-align: center;
}
.about-asymmetric-text p {
  font-size: 4.5rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media (max-width: 500px) {
  .about-asymmetric-text p {
    font-size: 30px;
  } 
 }
.about-asymmetric-text p i {
  font-style: italic;
  font-weight: 600;
}
.about-content span, .about-content p, .about-content p, .about-content span {
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about-content {
  margin-top: 7rem;
  margin-bottom: 14.5rem;
}
@media (max-width: 500px) {
  .about-content>div {
    flex-direction: column;
  } 
  .about-content>div>div {
    width: 100%;
  } 
  .about-content span, .about-content p, .about-content p, .about-content span {
    font-size: 3rem;
  }
 }

/* End about page */


/* Events page  */

.catalog-hero {
  position: relative;
}
@media (max-width: 500px) {
  .catalog-hero {
     margin-bottom: 5rem;
   }
 }
.catalog-hero-inner,
.catalog-gallery-inner {
  border: 1px solid #262626;
  border-radius: 1.5rem;
  padding: 3rem 2rem;
  height: 100%;
}
.catalog-hero-banner {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0px);
}
.catalog-hero-top {
  color: #fff;
  font-size: 2.5rem;
}
@media (max-width: 500px) {
  .catalog-hero-top img:not(.circle) {
     width: 4rem;
   }
 }
.catalog-hero-top img.circle {
  width: 2rem;
}
.catalog-hero-bottom span {
  color: #fff;
  font-size: 10rem;
  font-weight: 600;
  margin-left: 3rem;
}
@media (max-width: 500px) {
  .catalog-hero-bottom span {
     font-size: 3rem;
   }
 }
.catalog-hero-bottom small {
  color: #fff;
  font-size: 2.5rem;
  font-style: italic;
}
.catalog-gallery-left {
  border-right: 1px solid #262626;
}
@media (max-width: 500px) { 
  .catalog-gallery-left img,
  .catalog-gallery-right img  {
    width: 100%;
  }
}

.catalog-wrapper {
  margin-bottom: 12rem;
}
.project-qty {
  color: #fff;
  font-size: 3.3rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.project-qty span {
  font-size: 12rem;
}
.catalog-title {
  color: #fff;
  font-size: 4rem;
}
.catalog-info {
  color: #3A3A3A;
  height: 4rem;
  font-size: 2rem;
  gap: 14rem;
}
.catalog-inner > div {
  padding-right: 5rem;
}
@media (max-width: 500px) {
  .catalog-inner > div:first-child {
     display: none !important;
   }
   .catalog-inner > div:last-child {
    width: 100%;
  }
 }
.catalog-events  {
  position: relative;
}
.catalog-events:first-of-type {
  margin-top: 4.5rem;
}
.catalog-events span.year {
  color: #fff;
  position: absolute;
  left: -8rem;
  font-size: 2rem;
}
.catalog-events ul  {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 2rem;
}
.catalog-events ul li a {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  gap: 10rem;
  color: #A0A0A0;
  transition: all 500ms;
}
.catalog-events ul li a:hover {
  color: #fff;
 }
.catalog-events ul li a p {
  width: 9rem;
}
.catalog-event-banner {
  position: relative;
}
.catalog-event-banner a:nth-child(1) {
  position: absolute;
  left: 0rem;
  top: 4rem;
}
.catalog-event-banner a:nth-child(2) {
  left: 7rem;
  position: absolute;
  top: 9rem;
}
.catalog-event-banner a:nth-child(3) {
  position: absolute;
  left: 2rem;
  top: 20rem;
}

/* End events page */

/* animations */

 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }  
  
/* Fonts */


.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}




/* release gallery  */ 

/* duplication code need to refactor TODO */
#release-banner {
  width: 100%;
  display: flex;
  justify-content: center;
}
.release-banner {
  height: 100%;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  width: 100%;
  max-width: 68rem;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
}
.release-banner .overlay {
background-image: url(../public/images/global/catalog-1.png);
  position: absolute;
  top: 50%;
  width: 80rem;
  height: 43rem;
  z-index: -1;
  background-size: contain;
  background-position: center;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
}
@media (max-width: 500px) {
  .release-banner .overlay {
     width: 48rem;
   }
 }
.release-banner .overlay::before {
  content: '';
  background-color: rgb(15 15 15 / 62%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.release-banner .release-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.release-banner .release-img img {
  width: 100%;
}
.release-label {
  display: flex;
  gap: 1rem;
  align-self: flex-start;
  padding-left: 7rem;
}
.release-banner .release-label p {
  border-radius: 5rem;
  background: rgba(226, 226, 226, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 2rem 3.25rem;
  color: #fff;
  font-size: 2.438rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.release-banner .release-links {
  align-items: center;
  align-self: end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  padding-right: 2rem;
}
.release-banner .release-links a {
  background: rgba(226, 226, 226, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 5rem;
  width: 7.5rem;
  height: 6.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 500px) {
  .release-banner .release-links a svg {
     width: 4rem;
   }
 }
.release-banner .release-links p svg {
}
.release-banner .release-links a:nth-child(3) {
  margin-left: 2rem;
}
.release-banner .release-links a:nth-child(4) {
  margin-left: 1rem;
}
.release-banner .release-name p {
  font-size: 15.625rem;
  color: #fff;
  align-self: flex-start;
  padding-right: 33rem;
}
@media (max-width: 500px) {
  .release-banner .release-name p {
     font-size: 6rem;
   }
 }
.release-banner .release-name .release-name-label {
font-size: 2rem;
color: #A0A0A0;
}
.release-archive-name  {
  color: #fff;
  font-size: 3.5rem;
  margin-top: 15.5rem;
}
.release-archive-name  p {
  color: #fff;
  font-size: 3.5rem;
}
.release-archive-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  margin-top: 3.5rem;
  margin-bottom: 20rem;
}
@media (max-width: 500px) {
  .release-archive-gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
 }
.release-archive-gallery-img  img {
  object-fit: cover;
  width: 100%;

}
.release-archive-gallery-item {
  position: relative;
}
@media (max-width: 500px) {
  .release-archive-gallery-item .top-action {
    display: none;
  }
}
.release-archive-gallery-item:hover .top-action,
.release-archive-gallery-item:hover .bottom-action {
opacity: 1;
visibility: visible;
}
@media (max-width: 500px) {
  .bottom-action.album-name {
    opacity: 1;
    visibility: visible;
    position: relative;
    background: transparent;
    backdrop-filter: none;
    width: 100% !important;
  }
  .bottom-action.album-name span {
    padding: 2rem 0rem;
  }
  .bottom-action.album-name .link-button {
    position: absolute;
    right: 1rem;
    top: -6rem;
    background: rgba(226, 226, 226, 0.10);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 1rem;
    border-radius: 2rem;
  }
}
.bottom-action.album-name p {
  color: #fff;
  font-size: 3.5rem;
  font-weight: 600;
}
@media (max-width: 500px) {
  .bottom-action.album-name p {
    font-size: 2rem;
  }
}
.bottom-action.album-name p span {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 300;
  padding: 0;
}
@media (max-width: 500px) {
  .bottom-action.album-name p span {
    white-space: nowrap;
  }
}